.badgeStyles {
    :hover {
        cursor: pointer;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

        :active {
            // transform: scale(0.95);
            box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.2),
    }
}