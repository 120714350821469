.active {
    background-color: #eef8ff;
}

.dimensionItem {
    padding: 4px;
    border-radius: 4px;
    margin: 6px;
    &:hover {
        background-color: #eef8ff !important;
    }
}

.MuiListItemButton-root {
    background-color: '';
  }
  
//   .MuiListItemButton-root:hover {
//     background-color: 'un';
//   }