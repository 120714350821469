.container {
    background-image: url("../../map-background.png");
    background-size: cover; /* or 'contain' depending on your needs */
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;

}


.form-container {
    min-width: 300px;
    max-width: 600px;
    padding: 20px;
}

.overlay {
    position: fixed; /* Ensure it covers the entire screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
    /* z-index: 1000; Ensure it is above other elements */
    display: flex;
    justify-content: center;
    align-items: center;
  }