

  
  #details-box {
    z-index: 1;
    position: absolute;
    bottom: 5px;
    left: 5px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
  }
  
  #details-box > div {
    margin: 0 14px;
  }
  
  #customer-box {
    z-index: 2000;
    position: absolute;
    top: 5px;
    left: 5px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }


  .search-address-form {
    display: flex;
  }

  .search-icon {
    height: 40px;
    width: 40px;
  }

  .search-button-wrapper {
    display: flex;
    align-items: center;
    margin: 0 0 0 6px;
  }
  
  .search-address-container {
    display: flex;
  }


  .map-container.mode-draw_line_string > .mapboxgl-canvas-container.mapboxgl-interactive, .mapboxgl-ctrl-group button.mapboxgl-ctrl-compass {
    cursor: crosshair ;
  }

  .map-container.mode-simple-select > .mapboxgl-canvas-container.mapboxgl-interactive, .mapboxgl-ctrl-group button.mapboxgl-ctrl-compass {
    cursor: pointer ;
  }

  .cta-button {
    margin: 10px;
  }

  .mapboxgl-popup-content {
    padding: 0 5px;
  }


.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom {
  background: transparent;
}

.MuiTooltip-tooltip .MuiChip-root {
  background: white;
}